exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-codingbooks-js": () => import("./../../../src/pages/Codingbooks.js" /* webpackChunkName: "component---src-pages-codingbooks-js" */),
  "component---src-pages-dfs-js": () => import("./../../../src/pages/DFS.js" /* webpackChunkName: "component---src-pages-dfs-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-settlersof-fullstack-js": () => import("./../../../src/pages/SettlersofFullstack.js" /* webpackChunkName: "component---src-pages-settlersof-fullstack-js" */),
  "component---src-pages-stacktimer-js": () => import("./../../../src/pages/Stacktimer.js" /* webpackChunkName: "component---src-pages-stacktimer-js" */)
}

